import { VerticalCarousel } from '@components/common/components/VerticalCarousel'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser } from '@typesApp/teaser'
import { FC } from 'react'
import CountdownWrapper from '../../../../components/Countdown'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import {
  StyledContentPromotionBar,
  StyledContentSlide,
  StyledCountdownContainer,
  StyledPromotionBarButton,
  StyledTextPromotionBar,
  StyledWrapperPromotionBar,
} from './PromotionBar.style'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'
import { normalizedTeaserText } from '@utils/helpers'

interface PromotionBarProps {
  data?: IPlacement
}

const PromotionBar: FC<PromotionBarProps> = ({ data }) => {
  const teaserItems = data?.items.filter(isLXTeaser) || []
  const promoBarName = data?.name ?? ''

  return (
    <StyledWrapperPromotionBar
      data-cm-metadata={`[{"_":"properties.placement-${promoBarName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${promoBarName}"}]}]`}
    >
      <StyledContentPromotionBar>
        {teaserItems?.length === 1 ? (
          <TextLinkPromoBar item={teaserItems[0]} />
        ) : (
          <VerticalCarousel
            delay={5000}
            autoplay
            loop={teaserItems?.length >= 2}
            slidesPerView={1}
            allowTouchMove={false}
            centeredSlides
          >
            {teaserItems.map((item, index) => (
              <TextLinkPromoBar key={index} item={item} />
            ))}
          </VerticalCarousel>
        )}
      </StyledContentPromotionBar>
    </StyledWrapperPromotionBar>
  )
}

const TextLinkPromoBar: FC<{ item: ILXTeaser }> = ({ item }) => {
  const { basePath } = useStoreIdentity()
  const teaserLXCallToActionSettings = item.teaserLXCallToActionSettings[0]
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const text = normalizedTeaserText(item.teaserText1 || item.teaserText2)

  const callToActionText = teaserLXCallToActionSettings?.callToActionText
  const formattedUrl = teaserLXCallToActionSettings?.target?.formattedUrl
  const link = `${basePath}${formattedUrl ?? ''}`

  const countdownStartInMillis = Number(item.teaserCountdownStart) * 1000
  const countdownUntilInMillis = Number(item.teaserCountdownUntil) * 1000

  const isCountDownVisible =
    item.teaserCountdownStart !== '' &&
    item.teaserCountdownUntil !== '' &&
    new Date().getTime() > countdownStartInMillis &&
    new Date().getTime() < countdownUntilInMillis

  const textColor = item.teaserText1 ? item.teaserOverlay1Style : item.teaserOverlay2Style
  const textAlign = item.teaserText1 ? item.teaserOverlay1TextAlign : item.teaserOverlay2TextAlign

  return (
    <StyledContentSlide bgColor={item.teaserBackground}>
      {isCountDownVisible && (
        <CountdownWrapper countdownUntilInMillis={countdownUntilInMillis} Wrapper={StyledCountdownContainer} />
      )}

      <StyledTextPromotionBar
        hasCallToAction={!!callToActionText && !!link}
        textColor={textColor}
        textAlign={textAlign}
      >
        <Typography variant="subtitle2" component={'p'}>
          {text}
        </Typography>
      </StyledTextPromotionBar>

      {callToActionText && (
        <StyledPromotionBarButton
          data-element-id={`X_X_PromotionBar_${callToActionText}`}
          href={link}
          locale={locale}
          locationOrigin={locationOrigin}
          isLocaleDomain={isLocaleDomain}
        >
          {callToActionText}
        </StyledPromotionBarButton>
      )}
    </StyledContentSlide>
  )
}

export default PromotionBar
